import React from 'react';
import './Slider.css';

const Slider = () => {
      
    return (
        <div className='sliderArea'>
            <div className="sliderAreaInner MySize">
                {/* <SlickSlider {...settings}>
                    <div>
                        <img src={logo1} alt="" />
                    </div>
                    <div>
                        <img src={logo2} alt="" />
                    </div>
                    <div>
                        <img src={logo3} alt="" />
                    </div>
                    <div>
                        <img src={logo4} alt="" />
                    </div>
                    <div>
                        <img src={logo5} alt="" />
                    </div>
                    <div>
                        <img src={logo6} alt="" />
                    </div>
                    <div>
                        <img src={logo7} alt="" />
                    </div>
                    <div>
                    <img src={logo8} alt="" />
                    </div>
                </SlickSlider> */}
            </div>            
        </div>
    )
}

export default Slider
