import React from 'react'
import Services from '../components/Services/Services'

const Servicepage = () => {
    return (
        <div className="MySize pagePaddingTop">
            <Services More="yes" TopPara="yes" Heading="yes"/>
        </div>
    )
}

export default Servicepage