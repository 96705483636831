import React from 'react';
import './Hostingplan.css';

const HostingPlan = () => {        
    return (
        <div className='hostingPlan py-2'>
            <div className="hostingPlanInner MySize">

            </div>
        </div>
    )
}

export default HostingPlan;